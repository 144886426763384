<template>
  <div class="w-full">
    <div class="mx-auto flex flex-col w-full divide-y divide-gray">
      <div class="lb-navigation grid grid-cols-3 bg-white text-primary">
        <div class="flex items-center lg:hidden col-span-3">
          <UIMobileMenuButton />
          <Logo class="lb-nav-logo px-2.5" />
        </div>
        <div class="lg:flex hidden items-center space-x-8">
          <Logo class="lb-nav-logo" />
          <div class="flex items-center justify-start">
            <LayoutNavigationMenu />
          </div>
        </div>

        <!-- Arama Formu -->
        <SearchInput
          class="order-3 hidden lg:flex"
          is-expandabled="true"
        />

        <!-- Üst Sağ - Kullanıcı Menu Alanı-->
        <LayoutNavigationUserContentArea />
      </div>
      <SearchInput
        class="bg-white flex lg:hidden"
        v-if="isShowMobileSearch"
        is-expandabled="true"
      />
      <SearchCombobox />
    </div>
  </div>
</template>

<script setup>

const route = useRoute();
const router = useRouter();

const { isShowMenu, isShowMobileSearch } = useHelper();
const isBackgroundColor = useState("isBackgroundColor", () => false);
const changeColor = useState("changeColor", () => true);
const scrollPosition = useState("scrollPosition", () => null);
const isHomePage = useState("isHomePage", () => true);
const openCombobox = useState("openCombobox", () => false);
const isTopBasketCount = ref(false);

onMounted(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener("scroll", updateScroll);
  }
});
const updateScroll = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  scrollPosition.value = window.scrollY;
  if (scrollPosition.value > 100) {
    changeColor.value = false;
  } else {
    changeColor.value = true;
  }
};

watchEffect(() => {
  if (route.path === "/") {
    isHomePage.value = true;
  } else {
    isHomePage.value = false;
  }
});
</script>
